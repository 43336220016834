interface ProjectCardProps {
  taskOwner: string
  task: string
  url: string
  description: string
  technologies: string[]
}

function ProjectCard({ taskOwner, task, url, description, technologies }: ProjectCardProps) {
  const emptyUrl = () => {
    if (url.length !== 0) {
      return (
        <a href={url} target='blank'>
          Visit here
        </a>
      )
    }
    return <p>This project does not currenty have an url</p>
  }

  return (
    <div>
      <h1>{task}</h1>
      <h2>{taskOwner}</h2>
      {emptyUrl()}
      <p>{description}</p>
      {technologies.map((el) => {
        return <p>{el}</p>
      })}
    </div>
  )
}

export default ProjectCard

import { useState } from 'react'
import Footer from './footer/Footer'
import Menu from './menu/Menu'
import Profile from './profile/Profile'
import { Outlet } from 'react-router-dom'
import ShowMenuBtn from './menu/ShowMenuBtn'

const Layout = () => {
  const [isHidden, setIsHidden] = useState(true)
  return (
    <>
      <ShowMenuBtn setIsHidden={setIsHidden} isHidden={isHidden} />
      <Menu className={isHidden ? 'hide-menu' : 'show-menu'} setIsHidden={setIsHidden} />
      <Profile />
      <Footer />
      <Outlet />
    </>
  )
}

export default Layout

import { FaGripLines } from 'react-icons/fa'

interface ShowMenuBtnProps {
  setIsHidden: React.Dispatch<React.SetStateAction<boolean>>
  isHidden: boolean
}

function ShowMenuBtn({ setIsHidden, isHidden }: ShowMenuBtnProps) {
  return (
    <div id='show-menu-btn'>
      <FaGripLines
        onClick={() => {
          setIsHidden(!isHidden)
          console.log('Menu var changed')
        }}
      />
    </div>
  )
}

export default ShowMenuBtn

import './menu.css'
import { Link } from 'react-router-dom'
import { FaArrowLeft } from 'react-icons/fa'

interface MenuProps {
  className: string
  setIsHidden: React.Dispatch<React.SetStateAction<boolean>>
}

const Menu = ({ className, setIsHidden }: MenuProps) => {
  return (
    <div id='menu' className={className}>
      <FaArrowLeft
        onClick={() => {
          setIsHidden(true)
        }}
      />
      <ul>
        <li>
          <Link to='/education'>Education</Link>
        </li>
        <li>
          <Link to='/career'>Career</Link>
        </li>
        <li>
          <Link to='/projects'>Projects</Link>
        </li>
        <li>
          <Link to='/life'>Life</Link>
        </li>
      </ul>
    </div>
  )
}

export default Menu
